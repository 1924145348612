<script>
export default {
    setup() {
        const auth = useAuthStore();
        const notification = useNotificationStore();
        const pusher = usePusherStore();
        return { auth, notification, pusher };
    },
    data() {
        return {
            onLine: navigator.onLine,
            showBackOnline: false,
        };
    },
    computed: {
        ...mapGetters(useAuthStore, ["hasSession"]),
        ...mapGetters(useResponseStore, ["htmlError", "errors"]),
    },
    created() {
        // Uncommed kalau sudah ada implementasi API
        if (!this.hasSession) {
            this.$router.replace("/auths/login");
        }
    },
    methods: {
        ...mapActions(useAuthStore, ["loadSession", "getMe", "getPermission"]),
        registerNotification() {
            if ("Notification" in window) {
                Notification.requestPermission();
            } else {
                reject(error);
            }
        },
        popupNotif(eventName, data) {
            if (eventName == "notification" && data?.title) {
                this.$swal({
                    icon: "success",
                    position: "bottom-end",
                    showConfirmButton: false,
                    title: data?.title,
                    html: data?.message,
                    timer: 3000,
                    toast: true,
                }).then(async () => {
                    await this.notification.fetchAll().catch((err) => {});
                    await this.loadSession();

                    if ("Notification" in window) {
                        Notification.requestPermission().then((permission) => {
                            if (permission === "granted") {
                                if ("serviceWorker" in navigator) {
                                    // Use service worker for mobile notifications
                                    navigator.serviceWorker.ready.then(
                                        (registration) => {
                                            registration.showNotification(
                                                data?.title,
                                                {
                                                    body: data?.message,
                                                    icon: "/vorteks.ico",
                                                }
                                            );
                                        }
                                    );
                                } else {
                                    // Use regular Notification for desktop
                                    const notification = new Notification(
                                        data?.title,
                                        {
                                            body: data?.message,
                                            icon: "/forteks.ico",
                                        }
                                    );

                                    // You can handle click events on the notification
                                    notification.onclick = () => {
                                        window.location =
                                            window.location.origin + data.link;
                                    };
                                }
                            }
                        });
                    } else {
                        reject(error);
                    }
                });
            }
        },
        updateOnlineStatus(e) {
            const { type } = e;
            this.onLine = type === "online";
        },
        async registerWebsocket() {
            await this.pusher.registerConnection();
            await this.pusher.registerChannel(
                "private-notification." + this.auth.user.id
            );
            await this.pusher.stream.bind_global(this.popupNotif);
            window.addEventListener("online", this.updateOnlineStatus);
            window.addEventListener("offline", this.updateOnlineStatus);
        },
    },
    async mounted() {
        if (!this.auth) return;
        if (!this.auth.token) return;
        if (!this.auth.user) return;
    },
    beforeDestroy() {
        window.removeEventListener("online", this.updateOnlineStatus);
        window.removeEventListener("offline", this.updateOnlineStatus);
    },
    watch: {
        onLine(v) {
            if (v) {
                this.showBackOnline = true;
                setTimeout(() => {
                    this.showBackOnline = false;
                }, 1000);
            }
        },
        "auth.user.id": {
            handler(payload) {
                if (payload) {
                    this.registerNotification();
                    this.registerWebsocket();
                }
            },
            immediate: true,
        },
    },
};
</script>
<template>
    <div v-if="onLine">
        <DashboardHeader />
        <DashboardNavigation />
        <div class="container show margin-bottom-large" id="container">
            <RouterView />
            <div style="height: 60px" class="uk-hidden@m"></div>
        </div>
        <DashboardFooter />
    </div>
    <Offline v-else />
</template>
